import {QbParticipantBase} from "./base"
import {HEAD, PUT} from "@qb-assets-js/qb-fetch";

export class QbSlot extends QbParticipantBase {
    get defaults() {
        return {
            avatar: '',
            name: this.index ? '"Team\\00a0" var(--slot-index)' : "In no Team"
        };
    }

    get selector() {
        return `[itemid="${this.itemid}"]`;
    }

    get finalize() {
        if('slot_index' in self.ME) this.joinNode = this.index === ME.slot_index;
    }


    set member_only(value) { this.setClass(value, "member-only") }

    get member_only() { this.hasClass("member-only") }

    set slot_join_token(value) {
        const node = this.querySelector(".slot-join-token") || SPAN({
            class: "slot-join-token",
            beforeend: this,
            events: [this, "click"]
        });
        node.textContent = value;
    }

    set team_join_token(value) {
        const node = this.querySelector(".team-join-token") || SPAN({
            class: "team-join-token",
            beforeend: this,
            events: [this, "click"]
        });
        node.textContent = value;
    }

    set joinNode(checked) {
        const node = this.querySelector("label.join") || LABEL({
            beforeend: this,
            class: "join"
        }, INPUT({type: "radio", value: this.index, name: "join-slot", events: [this, {change: "apply"}]}),
            SPAN(_("Join"))
        );
        node.firstElementChild.checked = checked;
    }

    handleEvent_apply(evt) {
        HEAD(location.toString().replace(/\d+$/, `${evt.target.value}`)).result({
            "202": (d) => { console.log(202, d) },
            "406": (d) => {
               this.parentElement.querySelector(`[name="join-slot"][value="${self.ME.slot_index}"]`).checked = true;
               warn(_("You need a team code to join. Please ask one of the team members to give it to you."))
            },
            "102": (d) => { console.log(102, d) }
        });
    }

    handleEvent(evt) {
        if(/-join-token$/.test(evt.currentTarget.className))
                return navigator.clipboard.writeText(`${location.origin}/${evt.currentTarget.textContent}`);
        super.handleEvent(evt);
    }

    get identifier() {
        return JSON.stringify({
            itemtype: "Slot",
            itemid: this.itemid,
            show_id: Show.itemid
        });
    }

    set offline(_) {}

}

QbSlot.register;